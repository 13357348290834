import { useNavigate } from 'react-router';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import useEffectOnce from './../../../../../../80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import { QRSCAN, USER_SETTING } from '10.quickConnect.app/routes/paths';

const useData = (): UseDataProps => {
  // On récupère le store
  const {
    resetAllObservables,
    LoginStore: {
      logOutAsync,
      signInInfos: { userUPN },
      connectByMsal,
      setConnectByMsal,
    },
    DeclarationStore: {
      sendLogsToServerAsync,
      isEditingCurrentDeclaration,
      editableDeclaration,
      sendingAttachments,
      attachmentsToSend,
      hasDraftsDcl,
    },
    LoginMsalStore: { isLogoutByMsal, setIsLogoutByMsal },
    CommonStore: { loadingRequests },
    UserSettingsStore: { muiMode, switchMuiMode },
  } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [openSignOutDialog, setOpenSignOutDialog] = useState<boolean>(false);

  // On définit le state
  const { t: tAxios } = useTranslation('axios');

  // On ajoute des hooks
  const navigate = useNavigate();

  const goToScan = () => {
    navigate(QRSCAN, { replace: false });
  };

  const isOnline = useNavigatorOnLine();

  const onLogOut = useCallback(async () => {
    // Fermons la dialogue si necessaire
    if (openSignOutDialog) setOpenSignOutDialog(false);

    // Nous devons prendre en compte les cas avec MSAL et les connexions QC

    if (connectByMsal === false) {
      // traitement déconnexion par msal
      setConnectByMsal(false);
      setIsLogoutByMsal(true);
    }
    // Envoi des logs
    const sentLogs = await sendLogsToServerAsync(userUPN);
    if (sentLogs) {
      await logOutAsync(tAxios);
      // Arret de tous les observables.
      resetAllObservables();
    }
  }, [
    openSignOutDialog,
    resetAllObservables,
    logOutAsync,
    tAxios,
    sendLogsToServerAsync,
    userUPN,
    connectByMsal,
    setConnectByMsal,
    setIsLogoutByMsal,
  ]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleMoveTo = useCallback(() => {
    navigate(USER_SETTING);
  }, [navigate]);

  // Gestion de la dialogue
  const closeSignOutDialog = useCallback(() => {
    setOpenSignOutDialog(false);
  }, []);

  const wantLogout = useCallback(async () => {
    const displaySignOutDialog = await hasDraftsDcl(userUPN);

    if (!displaySignOutDialog) {
      await onLogOut();
    } else {
      setOpenSignOutDialog(displaySignOutDialog);
    }
  }, [hasDraftsDcl, onLogOut, userUPN]);

  useEffectOnce(() => {
    if (isLogoutByMsal) {
      onLogOut();
    }
  });

  return {
    editableDeclaration,
    onLogOut,
    isOnline,
    goToScan,
    loadingRequests,
    userUPN,
    muiMode,
    switchMuiMode,
    handleClick,
    handleClose,
    handleMoveTo,
    open,
    anchorEl,
    isEditingCurrentDeclaration,
    sendingAttachments,
    attachmentsToSend,
    wantLogout,
    closeSignOutDialog,
    openSignOutDialog,
  };
};

export default useData;
