import React, { useMemo } from 'react';
import { Badge, Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudIcon from '@mui/icons-material/Cloud';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Fade from '@mui/material/Fade';
import PersonIcon from '@mui/icons-material/Person';
import useData from './hooks';

import { RightItemsProps } from './types';
import { RightItemsName } from './const';
import { UserButtonAction } from './style';
import { CustomAttachmentsToSendToolTip } from '10.quickConnect.app/components/shared/LayOut/TopBar/RightItems/style';
import { pureWhiteColor } from '00.app/theme';
import { useWindowSize } from '80.quickConnect.Core/hooks';
import SignOutDialog from '10.quickConnect.app/components/shared/Dialogs/SignOutDialog';

const RightItems: React.FC<RightItemsProps> = ({ children }: RightItemsProps) => {
  const { t } = useTranslation(['common', 'axios']);
  const {
    isOnline,
    onLogOut,
    loadingRequests,
    userUPN,
    muiMode,
    switchMuiMode,
    handleClick,
    handleClose,
    handleMoveTo,
    anchorEl,
    open,
    isEditingCurrentDeclaration,
    editableDeclaration,
    sendingAttachments,
    attachmentsToSend,
    wantLogout,
    closeSignOutDialog,
    openSignOutDialog,
  } = useData();

  const theme = useTheme();
  const { width } = useWindowSize();

  // On est obligé de mémoïser le nombre de requetes...
  const numberLoadingResquest = useMemo(() => loadingRequests.length, [loadingRequests]);

  const loader = useMemo(
    () =>
      sendingAttachments ? (
        <CustomAttachmentsToSendToolTip
          title={t('qcapp_warning_cannot_logout_nbr_attachement', { ns: 'axios', count: attachmentsToSend })}
        >
          <Box sx={{ marginRight: '1vw' }}>
            <Badge badgeContent={attachmentsToSend} color="primary">
              <CircularProgress data-cy={`${RightItemsName}-CircularProgress`} />
            </Badge>
          </Box>
        </CustomAttachmentsToSendToolTip>
      ) : (
        <Tooltip title={loadingRequests.map((lr) => lr[1]).join('\n')}>
          <Box sx={{ marginRight: '1vw', visibility: numberLoadingResquest > 0 ? 'visible' : 'hidden' }}>
            <CircularProgress data-cy={`${RightItemsName}-CircularProgress`} />
          </Box>
        </Tooltip>
      ),
    [sendingAttachments, attachmentsToSend, loadingRequests, t, numberLoadingResquest],
  );

  return (
    <Box
      data-cy={RightItemsName}
      sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 0px', justifyContent: 'flex-end' }}
    >
      {children || (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: { sm: 'space-evenly', md: 'space-evenly', lg: 'space-between' },
          }}
        >
          {loader}
          <Box>
            {
              <IconButton onClick={switchMuiMode} sx={{ marginRight: '1vw', color: pureWhiteColor, padding: 0 }}>
                {muiMode === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            }
          </Box>
          {isOnline ? (
            <Tooltip title={t('qcapp_common_online').toString()} sx={{ marginRight: '1vw' }}>
              <CloudIcon color="success" />
            </Tooltip>
          ) : (
            <Tooltip title={t('qcapp_warning_no_network').toString()} sx={{ marginRight: '1vw' }}>
              <CloudOffIcon color="error" />
            </Tooltip>
          )}

          <UserButtonAction
            variant="outlined"
            color="lightThemeTextColor"
            disabled={isEditingCurrentDeclaration && editableDeclaration}
            data-cy={`${RightItemsName}-profile-btn`}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={width && width < theme.breakpoints.values.md && <PersonIcon />}
          >
            {width && width > theme.breakpoints.values.md && userUPN}
          </UserButtonAction>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleMoveTo}>
              <ListItemIcon>
                <IconButton data-cy={`${RightItemsName}-settings-btn`} sx={{ marginRight: '1vw' }}>
                  <ManageAccountsIcon />
                </IconButton>
              </ListItemIcon>
              <Typography variant="inherit">{t('qcapp_action_settings').toString()}</Typography>
            </MenuItem>
            <MenuItem onClick={wantLogout}>
              <ListItemIcon>
                <IconButton data-cy={`${RightItemsName}-logout-btn`} onClick={wantLogout} sx={{ marginRight: '1vw' }}>
                  <ExitToAppIcon />
                </IconButton>
              </ListItemIcon>
              <Typography variant="inherit">{t('qcapp_action_logout').toString()}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
      <SignOutDialog open={openSignOutDialog} onClose={closeSignOutDialog} confirmSignOutAction={onLogOut} />
    </Box>
  );
};

export default observer(RightItems);
