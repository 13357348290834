import { InboxViewer } from '90.quickConnect.Models/models';

const mapBaseInboxItem = (inboxItem: InboxViewer): InboxViewer =>
  ({
    id: inboxItem.id,
    inboxType: inboxItem.inboxType,
    sender: inboxItem.sender,
    subject: inboxItem.subject,
    sentAt: inboxItem.sentAt ? new Date(inboxItem.sentAt) : new Date(),
    folderPath: inboxItem.folderPath ?? '',
    formId: inboxItem.formId ?? '',
    activityId: inboxItem.activityId ?? '',
  } as InboxViewer);

export default mapBaseInboxItem;
