import { action, computed, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { AxiosError } from 'axios';
import { AbortRequestsStore } from '../interfaces';
import ViewStore from '../ViewStore';
import { GetInboxViewerResponse } from './Payloads/responses';
import { InboxViewer, TodoListTask } from '90.quickConnect.Models/models';
import { mapBaseInboxItem } from '90.quickConnect.Models/mappings';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { InboxItemType } from '90.quickConnect.Models/enums';
import { API_GET_INBOXES, API_GET_TODO_LIST_TASKS } from '40.quickConnect.DataAccess/axios/apiRoutes';
import RootStore from '30.quickConnect.Stores/RootStore';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import inboxesDb from '40.quickConnect.DataAccess/indexedDb/dbs/inboxesDb';
import { errorHandler } from '80.quickConnect.Core/helpers';

class InboxesStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/InboxesStores/index.ts';

  clientHTTP: IClientHTTP;

  // Ne pas mettre dans resetStore
  shouldAbortRequests = false;

  numberPreFilledDisplayed = 0;

  PreFilledStore: ViewStore<InboxViewer>;

  TasksStore: ViewStore<InboxViewer>;

  OthersStore: ViewStore<InboxViewer>;

  logger: CustomLogger;

  RootStore: RootStore;

  filter = '';

  finishedTasks: TodoListTask[] = [];

  allTask: TodoListTask[] = [];

  inprogressTask: TodoListTask[] = [];

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    makeAutoObservable(
      this,
      {
        finishedTasks: observable,
        allTask: observable,
        inprogressTask: observable,
        inboxesTotalNumber: computed,
        prefilledItemsCount: computed,
        tasksItemsCount: computed,
        othersItemsCount: computed,

        setShouldAbortRequests: action,
        getInboxItems: action.bound,
        setInprogressTask: action.bound,
        setFinishedTask: action.bound,
        resetTasks: action.bound,
        setAllTask: action.bound,
      },
      { autoBind: true },
    );

    this.PreFilledStore = new ViewStore<InboxViewer>(this.logger, 'PreFilledStore');
    this.TasksStore = new ViewStore<InboxViewer>(this.logger, 'TasksStore');
    this.OthersStore = new ViewStore<InboxViewer>(this.logger, 'OthersStore');
  }

  resetStore = (): void => {
    this.numberPreFilledDisplayed = 0;
    this.PreFilledStore.reset();
    this.TasksStore.reset();
    this.OthersStore.reset();
    this.filter = '';
  };

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  setInprogressTask = (tasks: TodoListTask[]) => {
    this.inprogressTask = tasks;
  };

  setFinishedTask = (tasks: TodoListTask[]) => {
    this.finishedTasks = tasks;
  };

  changeTaskState = (task: TodoListTask, state: boolean) => {
    if (state) {
      this.inprogressTask = this.inprogressTask.filter((x) => x.id !== task.id);
      this.setFinishedTask([...this.finishedTasks, task]);
    } else {
      this.finishedTasks = this.finishedTasks.filter((x) => x.id !== task.id);
      this.inprogressTask = [...this.inprogressTask, task];
    }
  };

  async getInboxItems(): Promise<void> {
    const {
      LoginStore: {
        signInInfos: { userUPN: forThisUPN },
      },
    } = this.RootStore;
    try {
      if (!this.shouldAbortRequests) {
        // Mise a jour de uri
        const getInboxesUri = API_GET_INBOXES.replace(':forThisUPN', forThisUPN);

        const response = await this.clientHTTP.get<GetInboxViewerResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(getInboxesUri),
          { withCredentials: true },
        );
        let inboxes = response.data.inboxes.map(mapBaseInboxItem);
        inboxes = inboxes.sort((a, b) => a.subject.localeCompare(b.subject, undefined, { sensitivity: 'base' }));
        const preFilled = inboxes.filter((i) => i.inboxType === InboxItemType.PrefilledForm);
        const tasks = inboxes.filter((i) => i.inboxType === InboxItemType.Task);
        const others = inboxes.filter((i) => i.inboxType === InboxItemType.Unknow);

        // On doit traiter les id qui ont pu etre supprimés.
        const activityIdsAndSentAtDate = await inboxesDb.getActivityIdsAndSentAtDate(forThisUPN);
        if (activityIdsAndSentAtDate !== undefined && activityIdsAndSentAtDate.activityIds.length > 0) {
          const prefilledFiltered = this.reduceInboxDeleted(
            preFilled,
            activityIdsAndSentAtDate.activityIds,
            activityIdsAndSentAtDate.sentAt,
          );
          const tasksFiltered = this.reduceInboxDeleted(
            tasks,
            activityIdsAndSentAtDate.activityIds,
            activityIdsAndSentAtDate.sentAt,
          );
          const othersFiltered = this.reduceInboxDeleted(
            others,
            activityIdsAndSentAtDate.activityIds,
            activityIdsAndSentAtDate.sentAt,
          );
          this.PreFilledStore.setItems(prefilledFiltered);
          this.TasksStore.setItems(tasksFiltered);
          this.OthersStore.setItems(othersFiltered);
        } else {
          this.PreFilledStore.setItems(preFilled);
          this.TasksStore.setItems(tasks);
          this.OthersStore.setItems(others);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetInboxViewerResponse>;
        errorAxios.message = `Une erreur est survenue lors de la récupération des inboxes pour cet utilisateur ${forThisUPN} : Statut: ${errorAxios.response?.data.status} - Message: ${errorAxios.response?.data.message}`;
        errorHandler(InboxesStore.TAG, errorAxios, 'getInboxItems');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération des inboxes cet utilisateur ${forThisUPN} : ${error.message}`;
        errorHandler(InboxesStore.TAG, error, 'getInboxItems');
      }
    }
  }

  reduceInboxDeleted = (items: InboxViewer[], activityIdsToFilter: string[], originalSentAt: Date): InboxViewer[] =>
    items.filter((item) => (activityIdsToFilter.includes(item.activityId) ? item.sentAt > originalSentAt : true));

  async removeInboxActivityId(userUPN: string, activityId?: string, sentAt?: Date): Promise<boolean> {
    if (!activityId) return false;

    const bInsertActivityId = await inboxesDb.addOrUpdateInboxAsync(activityId, userUPN, sentAt);

    if (!bInsertActivityId) return false;

    this.PreFilledStore.removeActivityId(activityId);
    this.TasksStore.removeActivityId(activityId);
    this.OthersStore.removeActivityId(activityId);

    return true;
  }

  get inboxesTotalNumber() {
    return this.prefilledItemsCount + this.tasksItemsCount + this.othersItemsCount;
  }

  get prefilledItemsCount() {
    return this.PreFilledStore.items?.length ?? 0;
  }

  get tasksItemsCount() {
    return this.TasksStore.items?.length ?? 0;
  }

  get othersItemsCount() {
    return this.OthersStore.items?.length ?? 0;
  }

  filterThisInboxes = (type: InboxItemType) => {
    switch (type) {
      case InboxItemType.PrefilledForm:
        return this.PreFilledStore.items
          ? this.PreFilledStore.filterThis(this.PreFilledStore.items, (i: InboxViewer) => i.subject)
          : [];
      case InboxItemType.Task:
        return this.TasksStore.items
          ? this.TasksStore.filterThis(this.TasksStore.items, (i: InboxViewer) => i.subject)
          : [];
      default:
      case InboxItemType.Unknow:
        return this.OthersStore.items
          ? this.OthersStore.filterThis(this.OthersStore.items, (i: InboxViewer) => i.subject)
          : [];
    }
  };

  setAllTask = (data: TodoListTask[]) => {
    this.allTask = data;
  };

  resetTasks = () => {
    this.setInprogressTask([]);
    this.setFinishedTask([]);
    this.setAllTask([]);
  };

  async getTodoListTask(activityId: string) {
    try {
      const result = await this.clientHTTP.post(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_TODO_LIST_TASKS),
        { id: activityId },
        {
          withCredentials: true,
        },
      );
      if (result) {
        const { data } = result;
        this.setAllTask(data.todoListTasks);

        this.setInprogressTask(data.todoListTasks.filter((x: TodoListTask) => x.state == 0));
        const inprogressTaskData = this.inprogressTask.map(
          (x: TodoListTask) => x && { ...x, comment: '', attachment: null },
        );
        this.setInprogressTask(inprogressTaskData);
        this.setFinishedTask(data.todoListTasks.filter((x: TodoListTask) => x.state == 100));
        this.setFinishedTask(this.finishedTasks.map((x: TodoListTask) => x && { ...x, comment: '', attachment: null }));

        return data;
      }
    } catch (error) {
      errorHandler(InboxesStore.TAG, error, 'getTodoListTask');
    }
  }
}
export default InboxesStore;
