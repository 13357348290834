import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { AxiosError } from 'axios';
import { AbortRequestsStore } from '../interfaces';
import ViewStore from '../ViewStore';
import { GetFormViewerResponse } from './Payloads/responses';
import { FormViewer } from '90.quickConnect.Models/models';
import { mapBaseForm } from '90.quickConnect.Models/mappings';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { API_GET_FORM_LIST } from '40.quickConnect.DataAccess/axios/apiRoutes';
import RootStore from '30.quickConnect.Stores/RootStore';
import formsDb from '40.quickConnect.DataAccess/indexedDb/dbs/formsDb';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { errorHandler } from '80.quickConnect.Core/helpers';

class FormStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/FormStore/index.ts';

  clientHTTP: IClientHTTP;
  shouldAbortRequests = false;

  numberDisplayed = 0;

  MainMenuStore: ViewStore<FormViewer>;

  LeftMenuStore: ViewStore<FormViewer>;

  formsWithSchemaId: FormViewer[] | undefined;

  logger: CustomLogger;

  RootStore: RootStore;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    makeAutoObservable(
      this,
      {
        shouldAbortRequests: observable,
        formsWithSchemaId: observable,

        getFormsList: action.bound,
        setShouldAbortRequests: action,
        setFormWithSchemaId: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: this.constructor.name,
      properties: ['formsWithSchemaId'],
      storage: window.localStorage,
    });

    this.MainMenuStore = new ViewStore<FormViewer>(this.logger, 'MainMenuStore');

    this.LeftMenuStore = new ViewStore<FormViewer>(this.logger, 'LeftMenuStore');
  }

  setFormWithSchemaId = (newForms: FormViewer[] | undefined) => (this.formsWithSchemaId = newForms);

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  resetStore = (): void => {
    this.shouldAbortRequests = false;
    this.formsWithSchemaId = undefined;
    this.MainMenuStore.reset();
    this.LeftMenuStore.reset();
  };

  public getFormsList = async (): Promise<void> => {
    const {
      LoginStore: {
        signInInfos: { userUPN: forThisUPN },
      },
    } = this.RootStore;

    try {
      if (!this.shouldAbortRequests) {
        // Mise a jour de l'url avec le userUPN enregistré

        const getFormViewerUri = API_GET_FORM_LIST.replace(':forThisUPN', forThisUPN);

        const response = await this.clientHTTP.get<GetFormViewerResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(getFormViewerUri),
          {
            withCredentials: true,
          },
        );
        const allForms = response.data.forms.map(mapBaseForm);
        const forms = allForms.filter((f) => !f.entitySchemaId);
        const orderedForms = forms.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.MainMenuStore.setItems(orderedForms);
        this.LeftMenuStore.setItems(orderedForms);

        const formsWithEntityId = allForms.filter((f) => f.entitySchemaId);
        const orderedFormsWithEntityId = formsWithEntityId.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
        );
        this.setFormWithSchemaId(orderedFormsWithEntityId);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetFormViewerResponse>;
        errorAxios.message = `Une erreur est survenue lors de la récupération des formulaires pour l'utilisateur "${forThisUPN}": Statut: ${errorAxios.response?.data.status}, Message: ${error.response?.data.message}`;
        errorHandler(FormStore.TAG, errorAxios, 'getFormsList');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération des formulaires pour l'utilisateur ${forThisUPN}: ${error.message}`;

        errorHandler(FormStore.TAG, error, 'getFormsList');
      }
    }
  };
}
export default FormStore;
