import { FieldType } from '90.quickConnect.Models/enums';
import {
  AllFieldValueTypes,
  FieldData,
  FieldDesc,
  QCActionData,
  QCActionValueData,
} from '90.quickConnect.Models/models';

export const mapActionData = ({ value }: FieldDesc): AllFieldValueTypes => {
  if (!Array.isArray(value)) return value;
  const actionValue = value as QCActionData;

  return actionValue.map((fieldData: FieldData) => {
    const { value: valueForActionValueData, fieldType, id, label } = fieldData as QCActionValueData;

    const valueFormatted =
      fieldType === FieldType.CheckBoxList
        ? valueForActionValueData
        : Array.isArray(valueForActionValueData) && valueForActionValueData.length === 1
        ? valueForActionValueData.at(0)
        : null;

    return {
      fieldType,
      id,
      label,
      value: valueFormatted,
    };
  });
};
