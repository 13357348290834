import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';

export const useData = <T extends TreeDataItem>(
  filter: string,
  getName: (item: T) => string,
  setItemsTree: (getName: (item: T) => string) => void,
  setExpandedNodes: (nodeIds: string[]) => void,
): UseDataProps => {
  // On récupère le store
  const {
    CommonStore: { setWorkInProgress, setWorkDone },
  } = useStore();

  const { t } = useTranslation('inboxes');

  // On définit le state
  const buildingTreeViewJob = useMemo(() => [uuidv4(), t('jobMessage.buildingTreeView')] as [string, string], [t]);

  // On construit l'arbre des inboxes
  useEffect(() => {
    setWorkInProgress(buildingTreeViewJob);
    setItemsTree(getName);
    setWorkDone(buildingTreeViewJob[0]);
    // Ne pas enlever inboxFilter des dépendances de ce useEffect sinon le filtre n'est plus pris en compte!
  }, [filter, buildingTreeViewJob, setWorkDone, setWorkInProgress, setItemsTree, getName]);

  // On ajout des hooks
  const handleToggle = useCallback(
    (event: SyntheticEvent, nodeIds: string[]) => {
      setExpandedNodes(nodeIds);
    },
    [setExpandedNodes],
  );

  return {
    handleToggle,
    t,
  };
};
