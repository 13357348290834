import { TreeDataItem } from './type';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';
import { groupBy } from '80.quickConnect.Core/helpers';

export const setTree = <T extends TreeDataItem>(
  flatItems: T[],
  filterForms: (toFilter: T[], getName: (item: T) => string) => T[],
  getName: (item: T) => string,
): [TreeViewData<T>[], string[]] => {
  if (flatItems.length > 0) {
    const filteredForms = filterForms(flatItems, getName);
    const groups = groupBy(filteredForms, (item) => item.folderPath);
    const sortedPath = groups.slice().sort((a, b) => a[0].localeCompare(b[0], undefined, { sensitivity: 'base' }));
    sortedPath.forEach(
      (sortedFolder) =>
        (sortedFolder[1] = sortedFolder[1]
          .slice()
          .sort((a, b) => getName(a).localeCompare(getName(b), undefined, { sensitivity: 'base' }))),
    );
    const newTreeNodeKeys: string[] = [];
    const buildTreeFromPath = (tree: TreeViewData<T>[], path: [string, T[]]): TreeViewData<T>[] => {
      const folderNames = path[0].split('\\');
      const buildTree = (parentKey: string, folderName: string, index: number): string => {
        // On calcul la key dans l'arbre
        const currentKey = parentKey ? `${parentKey}.${folderName}` : folderName;

        const searchInTree = (element: TreeViewData<T>, id: string): TreeViewData<T> | undefined => {
          if (element.id === id) return element;
          else if (element.items) return element.items.map((child) => searchInTree(child, id)).find((i) => !!i);
          return undefined;
        };

        // On regarde si il existe déjà un element avec cette clés
        let node = tree.map((child) => searchInTree(child, currentKey)).find((i) => !!i);
        if (!node) {
          node = {
            id: currentKey,
            name: folderName,
            data: undefined,
            items: [],
          } as TreeViewData<T>;
          if (parentKey) {
            const parent = tree.map((root) => searchInTree(root, parentKey)).find((i) => !!i);
            if (parent) {
              parent.items.push(node);
              newTreeNodeKeys.push(node.id);
            }
          } else {
            tree.push(node);
            newTreeNodeKeys.push(node.id);
          }
        }
        if (folderNames.length - 1 === index) {
          path[1].forEach((form) => {
            const formNode = tree.map((child) => searchInTree(child, form.id)).find((i) => !!i);
            if (!formNode) {
              node?.items.push({
                id: form.id,
                name: getName(form),
                data: form,
                items: [],
              } as TreeViewData<T>);
            }
          });
        }
        return currentKey;
      };
      folderNames.reduce(buildTree, '');
      return tree;
    };
    const newTree = sortedPath.reduce(buildTreeFromPath, [] as TreeViewData<T>[]);
    return [newTree, newTreeNodeKeys];
  } else {
    return [[], []];
  }
};
