import React from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, useTheme } from '@mui/material';
import { ContainerFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import {
  DialogStyle,
  DialogContentStyle,
  PaperPropsStyle,
  DialogActionsStyle,
  DialogQCFieldButtonCustomized,
  DialogValidateButtonStyle,
  DialogCancelButtonStyle,
  DialogCancelButtonCustom,
} from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/styles';
import { DialogQcFieldName } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/const';
import { DialogDesc } from '90.quickConnect.Models/models';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import useData from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/hooks';
import DialogSummary from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';

const DialogQcField = ({
  field,
  updateDeclaration,
  updateItemsForThisField,
  updateFieldErrors,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  context,
  labelClass,
  openByImagesGroupField,
}: ContainerFieldsTypesProps) => {
  const dialogField = field as DialogDesc;
  const { fullPathId, summary, items, errors } = dialogField;
  const { t, nameWithId, handleOpen, handleCancel, handleSubmit, open, dialogQCFieldRef, isDrawerOpened } = useData(
    updateDeclaration,
    dialogField,
    setSelectedIndex,
    updateFieldErrors,
    updateItemsForThisField,
  );
  const { breakpoints, palette } = useTheme();

  if (openByImagesGroupField)
    return (
      <Box data-cy={DialogQcFieldName} ref={dialogQCFieldRef} sx={DialogStyle(breakpoints, palette)}>
        <Box sx={DialogContentStyle(breakpoints, isDrawerOpened)} data-cy={`${DialogQcFieldName}-content`}>
          {items.map((item) => {
            const key = getFieldKey(item);
            return item?.importance ? (
              <Box sx={C2SImportant(item.importance)}>
                <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                  <FieldRenderer
                    key={key}
                    field={item}
                    updateDeclaration={updateDeclaration}
                    updateItemsForThisField={updateItemsForThisField}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly}
                    flattenFields={flattenFields}
                    setDeclaration={setDeclaration}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                  />
                </MandatoryField>
              </Box>
            ) : (
              <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                <FieldRenderer
                  key={key}
                  field={item}
                  updateDeclaration={updateDeclaration}
                  updateItemsForThisField={updateItemsForThisField}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  isReadOnly={isReadOnly}
                  flattenFields={flattenFields}
                  setDeclaration={setDeclaration}
                  context={context}
                  updateFieldErrors={updateFieldErrors}
                />
              </MandatoryField>
            );
          })}
        </Box>
      </Box>
    );

  return (
    <Box className={labelClass} data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>{labelSpan}</Box>
        <DialogQCFieldButtonCustomized
          color={errors && errors.length > 0 ? 'error' : 'primary'}
          data-cy={`${nameWithId}-open`}
          variant="contained"
          onClick={handleOpen}
        >
          {t('fields_dialog_open')}
        </DialogQCFieldButtonCustomized>

        {/* on est obligé d'utiliser l'opérateur spread ([...items]) pour que react comprennent les updates */}
        {summary === true && <DialogSummary items={[...items]} parentId={fullPathId} isReadOnly={isReadOnly} />}
      </Box>

      {open && open === fullPathId && (
        <Box data-cy={DialogQcFieldName} ref={dialogQCFieldRef} sx={DialogStyle(breakpoints, palette)}>
          <Box sx={DialogContentStyle(breakpoints, isDrawerOpened)} data-cy={`${DialogQcFieldName}-content`}>
            {items.map((item) => {
              const key = getFieldKey(item);
              return item?.importance ? (
                <Box sx={C2SImportant(item.importance)}>
                  <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                    <FieldRenderer
                      key={key}
                      field={item}
                      updateDeclaration={updateDeclaration}
                      updateItemsForThisField={updateItemsForThisField}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      isReadOnly={isReadOnly}
                      flattenFields={flattenFields}
                      setDeclaration={setDeclaration}
                      context={context}
                      updateFieldErrors={updateFieldErrors}
                    />
                  </MandatoryField>
                </Box>
              ) : (
                <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                  <FieldRenderer
                    key={key}
                    field={item}
                    updateDeclaration={updateDeclaration}
                    updateItemsForThisField={updateItemsForThisField}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly}
                    flattenFields={flattenFields}
                    setDeclaration={setDeclaration}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                  />
                </MandatoryField>
              );
            })}
          </Box>
          <Box sx={DialogActionsStyle(breakpoints, palette)}>
            <DialogCancelButtonCustom
              variant="contained"
              data-cy={`${nameWithId}-cancel`}
              color="secondary"
              sx={DialogCancelButtonStyle(breakpoints, palette)}
              onClick={handleCancel}
            >
              {t('qcapp_dialog_action_cancel')}
            </DialogCancelButtonCustom>
            <Button
              variant="contained"
              data-cy={`${nameWithId}-submit`}
              sx={DialogValidateButtonStyle(breakpoints, palette)}
              onClick={handleSubmit}
            >
              {t('qcapp_dialog_action_validate')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default observer(DialogQcField);
