import { useNavigate } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { InboxViewer } from '90.quickConnect.Models/models';
import { DECLARATION_FROM_INBOX } from '10.quickConnect.app/routes/paths';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const useData = (inboxItem: TreeDataItem): UseDataProps => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { id, formId, activityId } = inboxItem as InboxViewer;

  // On ajoute des hooks
  const link = useMemo(
    () => DECLARATION_FROM_INBOX.replace(':formId', formId).replace(':inboxId', id).replace(':activityId', activityId),
    [formId, id, activityId],
  );

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const openDeclaration = useCallback(async () => {
    navigate(link, { state: { activityId: activityId } });
  }, [activityId, link, navigate]);

  const openInNewTab = useCallback(async () => {
    window.open(link);
    setContextMenu(null);
  }, [link]);

  const contextMenuClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const openContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    },
    [contextMenu],
  );

  return {
    openDeclaration,
    isMobile,
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
  };
};

export default useData;
