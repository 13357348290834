import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box } from '@mui/material';
import { IncludeQcFieldName } from './const';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { FieldDesc } from '90.quickConnect.Models/models';
import { ContainerFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { ImportanceLevel } from '90.quickConnect.Models/enums';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';
import { FieldsName } from '50.quickConnect.Fields/Fields/const';

const IncludeQcField = ({
  field,
  updateDeclaration,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  context,
  updateFieldErrors,
  labelClass,
  updateItemsForThisField,
}: ContainerFieldsTypesProps) => {
  const groupField = field as FieldDesc;
  const { id, items, fullPathId } = groupField;
  const nameWithId = `${IncludeQcFieldName}-${id}`;

  const { breakpoints } = useTheme();

  return (
    <Box className={labelClass} data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      {items.map((item) => {
        const key = getFieldKey(item);
        return item?.importance ? (
          <Box sx={C2SImportant(item.importance)}>
            <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
              <FieldRenderer
                key={key}
                field={item}
                updateItemsForThisField={updateItemsForThisField}
                updateDeclaration={updateDeclaration}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isReadOnly={isReadOnly}
                flattenFields={flattenFields}
                setDeclaration={setDeclaration}
                context={context}
                updateFieldErrors={updateFieldErrors}
              />
            </MandatoryField>
          </Box>
        ) : (
          <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
            <FieldRenderer
              key={key}
              field={item}
              updateItemsForThisField={updateItemsForThisField}
              updateDeclaration={updateDeclaration}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              isReadOnly={isReadOnly}
              flattenFields={flattenFields}
              setDeclaration={setDeclaration}
              context={context}
              updateFieldErrors={updateFieldErrors}
            />
          </MandatoryField>
        );
      })}
    </Box>
  );
};
export default observer(IncludeQcField);
