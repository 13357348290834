import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useData from './hooks';
import { InboxLineName } from './const';
import { getInboxIcon } from '10.quickConnect.app/components/domain/Home/helpers';
import {
  CustomListItemText,
  getAvatarStyle,
  getListItemAvatarStyle,
  getListItemButtonStyle,
} from '10.quickConnect.app/components/domain/Home/styles';
import { InboxViewer } from '90.quickConnect.Models/models';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';

const InboxLine = (inboxItem: TreeDataItem) => {
  const { t } = useTranslation('inboxes');
  const { openDeclaration, openContextMenu, contextMenu, contextMenuClose, openInNewTab } = useData(inboxItem);
  const { inboxType, subject, sentAt } = inboxItem as InboxViewer;

  const theme = useTheme();

  return (
    <ListItem data-cy={InboxLineName} disablePadding>
      <ListItemButton
        sx={getListItemButtonStyle(theme)}
        data-cy={`${InboxLineName}-${inboxItem.id}`}
        onContextMenu={openContextMenu}
        onClick={() => openDeclaration()}
      >
        <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
          <Avatar sx={getAvatarStyle(theme)}>{getInboxIcon(inboxType)}</Avatar>
        </ListItemAvatar>
        <CustomListItemText primary={subject} secondary={sentAt.toLocaleString()} />
      </ListItemButton>
      <Menu
        open={contextMenu !== null}
        onClose={contextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={openInNewTab}>{t('contextual_menu_open_in_new_tab')}</MenuItem>
      </Menu>
    </ListItem>
  );
};
export default InboxLine;
