import { FieldDesc } from '90.quickConnect.Models/models';

const filterEmptyAction = (f: FieldDesc, emptyActionIds: string[]): boolean => {
  if (emptyActionIds.includes(f.id)) return false;

  if (f.items.length > 0) f.items.filter((child) => filterEmptyAction(child, emptyActionIds));

  return true;
};

export default filterEmptyAction;
