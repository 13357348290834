import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'i18next';
import {
  FieldDesc,
  DeclarationContext,
  ItemData,
  StepDesc,
  EntityData,
  AllFieldValueTypes,
} from '90.quickConnect.Models/models';

export enum CommandType {
  Pause = 'Pause',
  Cancel = 'Cancel',
  Send = 'Send',
  Previous = 'Previous',
}

export type UseDataProps = {
  t: TFunction;
  openCommands: boolean;
  setOpenCommands: Dispatch<SetStateAction<boolean>>;
  showLeftMenu: boolean;
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>;
  sendDeclaration: (newItemData: ItemData) => Promise<boolean>;
  openHistory: boolean;
  setOpenHistory: Dispatch<SetStateAction<boolean>>;
  openDeclarationDialog: boolean;
  onCloseDialog: () => void;
  saveDeclarationInDraft: () => void;
  onExitDeclaration: () => void;
  onPreviousPage: () => void;
};

export type CommandsProps = {
  canSubmit: boolean;
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void;
  context: DeclarationContext;
  declaration: FieldDesc[];
  left?: number | undefined;
  declarationData: ItemData;
  setDeclarationData: Dispatch<SetStateAction<ItemData>>;
  steps: StepDesc[];
  flattenFields: FieldDesc[];
  activityId: string | undefined;
  inboxId: string | undefined;
  declarationId: string;
  isEditable: boolean;
};
