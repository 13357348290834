import { format } from 'date-fns';
import { FormEditedData } from '../formEditedData';
import { FormType } from '90.quickConnect.Models/enums';
import { dateFormatWithMillisecond } from '80.quickConnect.Core/helpers/dateFormat';

export type FormEditedDataDTO = {
  id: string;
  formId: string;
  formType: FormType;
  name: string;
  serializedBody: string;
  templateBodies: string | undefined;
  userUpn: string;
  attachmentCorrelationId: string;
  editedAt: Date | string | undefined;
  editedData: string;
  stringExtract: string;
  entitySchemaId: string | undefined;
  entityInstanceId: string | undefined;
  itemData: string | undefined;
  itemDataStatus: number | undefined;
  activityId: string | undefined;
  parentActivityId: string | undefined;
  formParentId: string;
  masterId: string | undefined;
  internalData: string | undefined;
};

export const convertFromFormEditedData = (formEditedData: FormEditedData): FormEditedDataDTO => {
  const formEditedDto = {
    id: formEditedData.id,
    formId: formEditedData.formId,
    formType: formEditedData.formType,
    name: formEditedData.name,
    serializedBody: formEditedData.serializedBody,
    templateBodies: formEditedData.templateBodies,
    userUpn: formEditedData.userUpn,
    attachmentCorrelationId: '',
    editedAt: format(formEditedData.editedAt, dateFormatWithMillisecond),
    editedData: formEditedData.editedData,
    stringExtract: formEditedData.stringExtract,
    entitySchemaId: formEditedData.entitySchemaId,
    entityInstanceId: formEditedData.entityInstanceId,
    itemData: formEditedData.itemData,
    itemDataStatus: formEditedData.itemDataStatus,
    activityId: formEditedData.activityId,
    parentActivityId: formEditedData.parentActivityId,
    formParentId: formEditedData.formParentId,
    masterId: '',
    internalData: formEditedData.internalData,
  } as FormEditedDataDTO;
  return formEditedDto;
};
