import { Box, Tooltip, IconButton, SvgIcon, useTheme } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import useData from './hooks';
import { LogoBox } from './style';
import { LeftItemsProps } from './types';
import { leftItemsName } from './const';
import { showOnMobile, hideOnMobile } from '20.formLib/sharedStyles';
import { LogoStandardMediumSvg } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/Medium';
import { LeftItemsStyle } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { iconsLogoStyle } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/styles';
import { LogoStandardSmallSvg } from '10.quickConnect.app/components/shared/Icons/Logo/Standard/Small';
import { useWindowSize } from '80.quickConnect.Core/hooks';

const LeftItems: React.FC<LeftItemsProps> = ({ children, showLeftMenuButton }: LeftItemsProps) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const { t } = useTranslation('common');
  const { goHome, showLeftMenu, setShowLeftMenu } = useData(showLeftMenuButton);

  return (
    <Box data-cy={leftItemsName} sx={LeftItemsStyle}>
      {showLeftMenuButton && !showLeftMenu && (
        <IconButton onClick={() => setShowLeftMenu(true)}>
          <MenuRoundedIcon />
        </IconButton>
      )}
      <>
        {/* {showBackButton ? <BackButton /> : <></>} */}
        <Box sx={showOnMobile}>
          {/* {showBackButton ? <BackButton /> : <MainMobileMenu />} */}
          <Box sx={{ ...LogoBox }}>
            <Tooltip title={t('qcapp_common_home_page').toString()}>
              <Box sx={iconsLogoStyle(theme)} onClick={goHome} data-cy={`${leftItemsName}-goHome-mobile`}>
                <SvgIcon component={LogoStandardMediumSvg} viewBox="inherit" fontSize="small" />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ ...hideOnMobile }}>
          <Tooltip title={t('qcapp_common_home_page').toString()}>
            <Box sx={iconsLogoStyle(theme)} onClick={goHome} data-cy={`${leftItemsName}-goHome-desktop`}>
              <SvgIcon
                component={width && width > theme.breakpoints.values.lg ? LogoStandardMediumSvg : LogoStandardSmallSvg}
                viewBox="inherit"
                fontSize="small"
              />
            </Box>
          </Tooltip>
        </Box>
      </>
      <Box sx={{ marginLeft: '1vw' }}>{children}</Box>
    </Box>
  );
};

export default observer(LeftItems);
