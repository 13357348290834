import React from 'react';
import { Box, ImageList, Paper, useTheme } from '@mui/material';
import { ImagesGroupQcFieldProps } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/types';
import { ImagesGroupDesc } from '90.quickConnect.Models/models';
import useData from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/hooks';
import ImageGroupItem from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { ImagesGroupProvider } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext';
import { ModalState } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/Modals/ModalState';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import DialogSummary from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary';

export default function ImagesGroupQcField(imagesGroupQcFieldProps: ImagesGroupQcFieldProps) {
  const { field, updateDeclaration, labelClass, labelSpan, flattenFields, isReadOnly } = imagesGroupQcFieldProps;
  const imagesGroupField = field as ImagesGroupDesc;

  const { fullPathId, toolTip, id, summary, items } = imagesGroupField;

  // Business Logic
  const {
    imagesList,
    imagesGroupContextValue,
    imagesGroupState: { fieldToShow },
    handleCancelButtonClick,
    handleValidateButtonClick,
  } = useData(imagesGroupField, updateDeclaration, flattenFields, isReadOnly);

  const isMobile = useIsMobile();

  const customTheme = useTheme();
  const { breakpoints } = customTheme;

  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <Paper
        elevation={1}
        sx={{
          padding: 2,
        }}
      >
        <Box sx={FlexRow}>
          {labelSpan}
          {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
        </Box>
        <ImagesGroupProvider value={imagesGroupContextValue}>
          <ImageList cols={isMobile ? 1 : 3} sx={{ padding: 1 }} rowHeight={150}>
            {/* Affichage de la grille */}
            {imagesList.length > 0 &&
              imagesList.map(([fieldId, url]: [string, string], index: number) => (
                <ImageGroupItem
                  key={`${imagesGroupField.fullPathId}-${fieldId}-${index.toString()}`}
                  fieldId={fieldId}
                  url={url}
                />
              ))}
          </ImageList>
          {fieldToShow && (
            <ModalState
              field={fieldToShow}
              isOpen={!!fieldToShow}
              handleValidateButtonClick={handleValidateButtonClick}
              handleCancelButtonClick={handleCancelButtonClick}
            >
              {fieldToShow.importance ? (
                <Box sx={C2SImportant(fieldToShow.importance)}>
                  <MandatoryField key={getFieldKey(fieldToShow)} fieldDesc={fieldToShow}>
                    <FieldRenderer {...imagesGroupQcFieldProps} field={fieldToShow} openByImagesGroupField />
                  </MandatoryField>
                </Box>
              ) : (
                <MandatoryField key={getFieldKey(fieldToShow)} fieldDesc={fieldToShow}>
                  <FieldRenderer {...imagesGroupQcFieldProps} field={fieldToShow} openByImagesGroupField />
                </MandatoryField>
              )}
            </ModalState>
          )}
          {summary && <DialogSummary parentId={fullPathId} items={[...items]} isReadOnly={isReadOnly} />}
        </ImagesGroupProvider>
      </Paper>
    </Box>
  );
}
