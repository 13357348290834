import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { setTree } from '30.quickConnect.Stores/helpers/setTree';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { InboxViewer } from '90.quickConnect.Models/models';

class ViewStore<T extends TreeDataItem> {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/ViewStore/index.ts';

  logger: CustomLogger;

  items: T[] | undefined;

  itemsTree: TreeViewData<T>[] | undefined;

  treeNodeKeys: string[] = [];

  expandedNodes: string[] = [];

  filter = '';

  numberDisplayed = 0;

  constructor(logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    makeAutoObservable(
      this,
      {
        filter: observable,
        numberDisplayed: observable,
        items: observable,
        itemsTree: observable,
        treeNodeKeys: observable,
        expandedNodes: observable,

        setFilter: action,
        setItems: action.bound,
        setItemsTree: action,
        setExpandedNodes: action,
        removeItem: action.bound,
      },
      { autoBind: true },
    );

    void makePersistable(this, {
      name: storageKey,
      properties: ['items', 'filter'],
      storage: window.localStorage,
    });
  }

  setItems = async (items: T[]) => {
    this.items = items;
  };

  reset = () => {
    this.items = [];
    this.itemsTree = undefined;
    this.treeNodeKeys = [];
    this.expandedNodes = [];
    this.numberDisplayed = 0;
  };

  setExpandedNodes = (expandedNodes: string[]) => (this.expandedNodes = expandedNodes);

  setFilter = (filter: string): void => {
    // On ignore les accents est les majuscules
    this.filter = filter
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  filterThis = (items: T[], getName: (item: T) => string) => this.filterDeclaration(items, getName);

  setItemsTree = async (getName: (item: T) => string) => {
    const [tree, treeNodes] = setTree(this.items ?? [], this.filterDeclaration, getName);
    this.itemsTree = tree;
    this.treeNodeKeys = treeNodes;
  };

  removeItem(itemId: string): void {
    if (this.items) {
      const index = this.items.findIndex((i) => i.id === itemId);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    }
  }

  removeActivityId(activityId: string): void {
    if (!this.items) return;

    const index = this.items.findIndex((i: T) => ('activityId' in i ? i.activityId === activityId : false));

    if (index !== -1) this.items.splice(index, 1);
  }

  private filterDeclaration(items: T[], getName: (item: T) => string): T[] {
    const filtered = this.filter
      ? // On ignore les accents est les majuscules
        items.filter((f) =>
          getName(f)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.filter),
        )
      : items;
    this.numberDisplayed = filtered.length;
    return filtered;
  }
}
export default ViewStore;
